@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/css?family=Rozha+One');
@import "./vars";

* {
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  margin: 0;
  box-sizing: border-box;
}

h1,h2 {
  font-family: 'Rozha One', serif;
}

h5 {
  font-size: 14px;
  letter-spacing: 2px;
}

.hide-transition {
  opacity: 0;
}

a {
  text-decoration: none;
}

p {
  font-size: 14px;
}

.mobile-only {
  @media (min-width: $mobile-width) {
    display: none !important;
  }
}

.desktop-only {
  @media (max-width: $mobile-width) {
    display: none !important;
  }
}