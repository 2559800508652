@import "./vars";

.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  .sidebar-actions {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    @media (min-width: $mobile-width) {
      padding: 20px;
    }
    .sidebar-action {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 10px;
      color: white;
      border-radius: 20px;
      background-color: rgba(0,0,0,0);
      transition: 0.2s ease background-color;
      @media (min-width: $mobile-width) {
        margin-bottom: 20px;
      }
      &:hover {
        background-color: rgba(0,0,0,0.1);
      }
      img {
        width: 40px;
        height: 40px;
        @media (min-width: $mobile-width) {
          width: 30px;
          height: 30px;
        }
      }
      p {
        font-weight: bold;
        font-size: 14px;
        @media (min-width: $mobile-width) {
          margin-top: 10px;
        }
      }
    }
  }
  .links {
    position:absolute;
    bottom: 20px;
    right: 20px;
    &, a {
      color: white;
      font-weight: bold;
    }
  }
}