@import "../../vars";

.audio-settings-screen {
  .settings-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .settings-content-wrapper {
    height: fit-content;
    margin-top: -20%;
  }
  .settings-item {
    text-align: center;
    color: white;
    padding: 40px 0;
    @media (min-width: $mobile-width) {
      padding: 40px;
    }
  }
  .settings-item-label {
    font-size: 18px;
    margin-bottom: 30px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .slider-input {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 75vw;
    position: relative;
    height: 20px;
    @media (min-width: $mobile-width) {
      width: 500px;
    }
    .slider-input-line, .slider-input-line-solid {
      background: white;
      border: 1px solid white;
      height: 2px;
      border-radius: 5px;
    }
    .slider-input-line {
      opacity: 0.2;
      position: absolute;
      width: 100%;
    }
    .slider-input-btn {
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: white;
      border: 1px solid white;
      transform: translate(-50%, 0%);
      cursor: pointer;
      &:hover {
        box-shadow: $glow-white;
      }
    }
  }
}