@import "../../vars";

@keyframes blinker {
  30% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
}

.main-audio-section {
  max-width: 80vw;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: $mobile-width) {
    width: 100%;
  }

  .audio-info-box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 10px;
    width: 100%;
    max-width: 220px;
    height: 200px;
    box-shadow: $glow-black;
    padding: 20px;
    @media (min-width: $mobile-width) {
      max-width: 260px;
      height: 240px;
    }
  }
  .status-indicator {
    position: absolute;
    top: 10px;
    left: 10px;
    border-radius: 8px;
    padding: 6px 8px;
    font-size: 14px;
    letter-spacing: 1px;
    color: white;
  }
  .live-indicator {
    background: red;
    .blinking-circle {
      animation: blinker 2s linear infinite;
    }
  }
  .down-indicator {
    background: $grey;
  }
  .action-icons {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .action-icon {
    background: $grey;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
    }
  }
  .music-credit-link {
    display: block;
    margin: 40px 0 20px 0;
    &:hover {
      text-decoration: underline white;
    }
    &.loading {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .music-name {
    display: flex;
    align-items: center;
    color: white;
    img {
      height: 15px;
      margin-right: 5px;
    }
  }
  .airport-code {
    font-size: 64px;
    line-height: 34px;
  }
  .airport-name {
    font-size: 20px;
    margin-top: 20px;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
  }
  .audio-floating-info {
    color: white;
    text-align: center;
    margin-bottom: 10px;
    margin-top: -20px;
    cursor: pointer;
  }
  .atc-frequency {
    font-size: 40px;
    line-height: 34px;
    margin: 30px 0 15px 0;
  }
  .atc-frequency-type {
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 13px;
    margin-bottom: 20px;
    @media (min-width: $mobile-width) {
      margin-bottom: 30px;
    }
  }
}

.help-box {
  text-align: center;
  h4 {
    margin-top: 10px;
    color: red;
    font-weight: bold;
  }
  p {
    
  }
  a.help {
    text-decoration: underline;
    color: red;
  }
}