@import "../../vars";

.feed-down-msg {
  color: white;
  width: 300px;
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  line-height: 24px;
  a {
    color: white;
    text-decoration: underline;
  }
}

.audio-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  &.disable {
    pointer-events: none;
    opacity: 0.5;
  }
  .audio-toggle-button {
    cursor: pointer;
    background: white;
    border: 1px solid white;
    border-radius: 50%;
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: $glow-white;
    transition: 0.2s ease box-shadow;
    margin: 0 40px;
    &:hover {
      box-shadow: $glow-white-2;
    }
  }
  .audio-control-icon-wrapper {
    text-align: center;
    cursor: pointer;
    margin-bottom: -10px;
    &:hover {
      .audio-control-label {
        opacity: 1;
      }
    }
  }
  .audio-control-label {
    margin-top: 10px;
    color: white;
    opacity: 0;
    transition: 0.2s ease opacity;
  }
  .audio-control-icon {
    height: 30px;
  }
  .audio-toggle-icon {
    height: 30px;
  }
}