.background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: black;
  .background-wrapper, .background-color-overlay {
    position: absolute;
    top: -5%;
    left: -5%;
    width: 110%;
    height: 110%;
  }
  .background-wrapper {
    background-size: cover;
    background-position: 50% 50%;
    filter: blur(8px) brightness(60%);
    -webkit-filter: blur(16px) brightness(30%);
  }
  .background-color-overlay {
    transition: 2s ease opacity;
    background: linear-gradient(rgba(255, 87, 87, 0.5), rgba(51,0,255,0.2));
  }
}