@import "../../vars";

$input-width: 400px;

.screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  .go-back-btn {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 20px;
    padding: 20px;
    @media (min-width: $mobile-width) {
      padding: 40px;
    }
  }
}

.change-airport-screen {
  padding-top: 15vh;
  .autocomplete-wrapper {
    position: relative;
    max-width: 80vw;
    margin: 0 auto;
  }
  .input-text {
    &:focus-visible {
      outline: none;
      background: rgba(0,0,0,0.4);
    }
  }
  .input-text, .autocomplete-item {
    background: rgba(0,0,0,0.4);
    width: 100%;
    height: 80px;
    border: none;
    color: white;
    font-size: 34px;
    padding: 20px;
    text-transform: uppercase;
    @media (min-width: $mobile-width) {
      width: $input-width;
    }
  }
  .input-text, .autocomplete-box {
    border-radius: 5px;
  }
  .autocomplete-box {
    position: absolute;
    margin-top: 20px;
    max-height: 50vh;
    overflow: auto;
    width: 100%;
  }
  .autocomplete-item {
    cursor: pointer;
    padding: 20px;
    background: rgba(0,0,0,0.4);
    &:hover {
      background: black;
    }
  }
  .autocomplete-nonhighlight {
    opacity: 0.5;
  }
  .autocomplete-highlight {
    opacity: 1;
  }
  .error-message {
    padding: 20px;
    font-size: 20px;
    color: white;
  }
  .or {
    color: white;
    text-align: center;
    margin-top: 30px;
  }
  .pick-btn {
    display: block;
    cursor: pointer;
    padding: 20px;
    background: white;
    border-radius: 5px;
    margin-top: 30px;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    color: black;
    img {
      margin-right: 6px;
      height: 20px;
    }
  }
}